<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.565 19.013"><path fill="#fff" d="M9.473 17.115a1.9 1.9 0 01-1.9 1.9 1.9 1.9 0 01-1.9-1.9c.006-1.047 3.8-1.047 3.8 0z"/><path d="M14.556 9.411v-1.1a5.756 5.756 0 00-5-5.7 1.19 1.19 0 00.429-.905A1.206 1.206 0 008.778.5a1.206 1.206 0 00-1.206 1.206 1.187 1.187 0 00.429.905 5.677 5.677 0 00-5 5.7v5.153l-2.509 3.14h16.565l-2.509-3.14z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconNotifications'
  };
</script>
