<template>
  <div class="notifications">
    <Banner>
      <div class="banner-content">
        <MeepIconNotifications
          class="banner-content__icon banner-content__icon-sub-inverted"
        />
        <span class="banner-content__text">{{ $t("noti.banner") }}</span>
      </div>
    </Banner>
    <div class="notifications-content page-layout">
      <md-card class="notifications__card md-layout-item md-size-100">
        <PageHeader
          :title="$t('noti.title')"
          :has-back="true"
          @back="backToHome"
        />

        <md-card-content>
          <md-list>
            <md-list-item>
              <span class="notifications__text md-list-item-text">
                Notifications : {{ hasUnreadNotif }}
              </span>

              <!-- Vider la liste -->
              <md-button
                class="md-icon-button md-list-action"
                @click="clearNotifications"
              >
                <MeepIconTopBarBoxDelete
                  :style="{ width: '30px' }"
                  class="app-icon"
                />

                <md-tooltip md-direction="top">
                  Vider la liste
                </md-tooltip>
              </md-button>
            </md-list-item>

            <md-list-item
              v-for="(notif, index) in notifications"
              :key="index"
              class="notification"
            >
              <div v-if="notif.icon === 'cloud'" class="icon-circle">
                <MeepIconCloud class="app-icon-stroke-inverted  toolbar-icon" />
              </div>

              <div v-if="notif.icon === 'notifications'" class="icon-circle">
                <MeepIconNotifications
                  class="app-icon-stroke-inverted toolbar-icon"
                />
              </div>

              <div v-if="notif.icon === 'person'" class="icon-circle">
                <MeepIconPerson class="app-icon-inverted toolbar-icon" />
              </div>

              <div v-if="notif.icon === 'library_books'" class="icon-circle">
                <MeepIconHomepageChat class="app-icon-inverted toolbar-icon" />
              </div>

              <div
                v-if="notif.icon === 'business' || notif.icon === 'work'"
                class="icon-circle"
              >
                <MeepIconSettingCompanies
                  class="app-icon-inverted toolbar-icon"
                />
              </div>

              <div
                v-if="
                  notif.icon === 'assignment' ||
                    notif.icon === 'assignment_late'
                "
                class="icon-circle"
              >
                <MeepIconAssignment class="app-icon-inverted toolbar-icon" />
              </div>

              <div
                class="md-list-item-text"
                @click="readNotif(notif.id)"
              >
                <router-link
                  class="text-secondary notifications__text"
                  v-if="notif.url"
                  :title="notif.text"
                  :to="notif.url"
                >
                  {{ notif.text }}
                </router-link>

                <span v-else :title="notif.text" class="text-secondary notifications__text">
                  {{ notif.text }}
                </span>
              </div>

              <!-- Bouton pour enlever l'élément de la liste -->
              <md-button
                class="md-icon-button md-list-action"
                @click="removeNotif(notif.id)"
              >
                <MeepIconTopBarBoxDelete
                  :style="{ width: '30px' }"
                  class="app-icon"
                />

                <md-tooltip md-direction="top">
                  Retirer de la liste
                </md-tooltip>
              </md-button>
            </md-list-item>
          </md-list>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import PageHeader from "../components/PageHeader";
import { mapGetters, mapActions } from "vuex";
import Banner from "@/components/Banner";
import HasBackMixin from "@/mixins/HasBackMixin";
import MeepIconCloud from "@/components/icons/MeepIconCloud";
import MeepIconNotifications from "@/components/icons/MeepIconNotifications";
import MeepIconPerson from "@/components/icons/MeepIconPerson";
import MeepIconHomepageChat from "@/components/icons/MeepIconHomepageChat";
import MeepIconSettingCompanies from "@/components/icons/MeepIconSettingCompanies";
import MeepIconAssignment from "@/components/icons/MeepIconAssignment";
import MeepIconTopBarBoxDelete from "@/components/icons/MeepIconTopBarBoxDelete";

export default {
  name: "Notifications",

  components: {
    PageHeader,
    Banner,
    MeepIconNotifications,
    MeepIconCloud,
    MeepIconPerson,
    MeepIconSettingCompanies,
    MeepIconAssignment,
    MeepIconHomepageChat,
    MeepIconTopBarBoxDelete,
  },

  mixins: [HasBackMixin],

  methods: {
    ...mapActions([
      "READ_NOTIFICATION",
      "REMOVE_NOTIFICATION",
      "REMOVE_ALL_NOTIFICATIONS",
    ]),

    readNotif(notifId) {
      this.READ_NOTIFICATION(notifId);
    },

    removeNotif(notifId) {
      this.REMOVE_NOTIFICATION(notifId);
    },

    clearNotifications() {
      this.REMOVE_ALL_NOTIFICATIONS();
    },
  },

  computed: {
    ...mapGetters(["notifications", "unreadNotifications"]),

    hasUnreadNotif() {
      return this.unreadNotifications.length;
    },
  },

  mounted() {
    this.$scrollTo(".notifications__card");
  },
};
</script>

<style lang="scss">
.notifications {
  &__card {
    border-radius: 10px;
    padding: 50px 40px;

    .md-card-content {
      padding: 0;
      margin-top: -15px;

      .icon-circle {
        margin-right: 15px;
        background-color: var(--bg-primary);
      }

      .md-list-item {
        &-content {
          padding: 4px 16px 4px 0;
        }
      }

      .md-list-action {
        .md-button-content {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  &__text {
    font-size: 17px;
  }

  &__title {
    color: var(--bg-primary);
  }

  &__sub-title {
    margin-top: 0.8rem;
  }
}
</style>
