<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.5 20.504a10 10 0 118.823-14.723.5.5 0 11-.882.473 9 9 0 10-7.941 13.25.5.5 0 010 1zM19.899 9.576a.5.5 0 01-.494-.428c-.07-.472-.172-.938-.307-1.395a.5.5 0 01.959-.283c.149.503.261 1.016.337 1.534a.5.5 0 01-.495.572zM19.876 12.568a.523.523 0 01-.265-.076.501.501 0 01-.23-.506c.078-.468.119-.94.122-1.415a.5.5 0 111 0c-.004.526-.05 1.052-.135 1.571a.5.5 0 01-.492.426zM18.924 15.402a.5.5 0 01-.443-.732c.22-.42.406-.857.557-1.306a.5.5 0 01.949.318c-.168.5-.375.985-.619 1.452a.5.5 0 01-.444.268zM17.139 17.8a.5.5 0 01-.349-.859c.339-.331.652-.689.935-1.069a.5.5 0 01.8.6c-.315.423-.662.82-1.04 1.187a.5.5 0 01-.346.14zM14.707 19.527a.5.5 0 01-.222-.948c.425-.21.832-.453 1.219-.727a.5.5 0 11.578.816 10 10 0 01-1.355.808.5.5 0 01-.22.051zM11.847 20.41a.502.502 0 01-.07-1c.47-.068.932-.171 1.385-.31a.5.5 0 01.594.709.503.503 0 01-.294.246 9.966 9.966 0 01-1.615.354z" fill="#fff"/><path d="M19.504 6.5h-4.5a.5.5 0 010-1h3.5V2a.5.5 0 111 0v4.5zM15.734 11.501h-5.77V4.153a.5.5 0 111 0V10.5h4.769a.5.5 0 010 1l.001.001z" fill="#fff"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconAssignment'
  };
</script>
