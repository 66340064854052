<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.02 5.947h-3.134v-.268A1.74 1.74 0 0014.093 4h-1.704a1.74 1.74 0 00-1.793 1.678v.268H7.462A1.518 1.518 0 006 7.513v.646a.632.632 0 00.632.632H19.85a.632.632 0 00.632-.632v-.646a1.518 1.518 0 00-1.462-1.566zm-7.62-.268a.963.963 0 01.993-.93h1.705a.963.963 0 01.992.93v.268H11.4v-.268zm7.864 2.26H7.222a.427.427 0 01-.426-.425.692.692 0 01.666-.714H19.02a.642.642 0 01.47.209.738.738 0 01.2.5.428.428 0 01-.43.43h.004zM13.077 10.536a.83.83 0 00-.8.856v7.257a.83.83 0 00.8.856h.329a.83.83 0 00.8-.856v-7.256a.83.83 0 00-.8-.856l-.33-.001zm.356.856v7.257a.028.028 0 01-.027.029h-.33c-.003 0-.007-.001-.01-.003a.028.028 0 01-.016-.026v-7.256c0-.004 0-.008.002-.011a.03.03 0 01.014-.016.03.03 0 01.01-.002h.33a.028.028 0 01.027.028zM10.278 10.536H9.95a.777.777 0 00-.584.272.9.9 0 00-.213.644l.473 7.256a.827.827 0 00.8.8h.327a.777.777 0 00.585-.272.9.9 0 00.212-.644l-.473-7.26a.827.827 0 00-.8-.796zm.493 8.13a.027.027 0 01-.02.01h-.327a.028.028 0 01-.027-.026l-.473-7.255a.03.03 0 01.007-.022.028.028 0 01.009-.007.028.028 0 01.01-.002h.328a.028.028 0 01.027.027l.473 7.258a.03.03 0 01-.008.018z" fill="#000"/><path d="M19.175 9.102a.386.386 0 00-.385.353l-.958 11.06a.8.8 0 01-.781.763H9.43a.8.8 0 01-.781-.763l-.96-11.06a.386.386 0 10-.77.067l.96 11.069a1.6 1.6 0 001.55 1.514h7.62a1.6 1.6 0 001.55-1.514l.959-11.07a.385.385 0 00-.385-.42z" fill="#000"/><path d="M16.206 10.536a.827.827 0 00-.8.8l-.473 7.255a.9.9 0 00.213.644.779.779 0 00.584.272h.327a.827.827 0 00.8-.8l.473-7.256a.9.9 0 00-.213-.644.78.78 0 00-.584-.272h-.327zm.354.858l-.473 7.256a.028.028 0 01-.027.027h-.327c-.004 0-.008 0-.011-.003a.026.026 0 01-.01-.006.03.03 0 01-.007-.022l.474-7.256c0-.007.003-.014.008-.019a.028.028 0 01.019-.008h.327c.004 0 .007 0 .01.002a.027.027 0 01.01.007.03.03 0 01.006.022z" fill="#000"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconTopBarBoxDelete'
  };
</script>
